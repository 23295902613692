
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'setfeature',
  props: {
    featureType: {
      type: String
    },
    noTextButton: {
      type: Object
    },
    yesTextButton: {
      type: Object
    }
  },
  data () {
    return {
      yesTextButtonText: '',
      noTextButtonText: '',
      getDisplay: true
    };
  },
  created () {
    this.yesTextButtonText = this.yesTextButton?.message ? this.$t(`${this.yesTextButton.message}`) : this.$t(`${this.yesTextButton}`);

    this.noTextButtonText = this.noTextButton?.message ? this.$t(`${this.noTextButton.message}`) : this.$t(`${this.noTextButton}`);
  },
  computed: {
    setDisplay () {
      return this.getDisplay ? '' : 'display: none';
    }
  },
  methods: {
    setResponse (userResponse:boolean):void {
      this.$emit('setResponse', { userResponse, featureType: this.featureType || null });
      this.getDisplay = false;
    }
  }
});
