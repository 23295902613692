import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "relative h-screen"
}
const _hoisted_5 = {
  id: "chatbot",
  class: "bg-inherit w-full h-80 absolute bottom-0 overflow-scroll grad fade"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Camera = _resolveComponent("Camera")!
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_ShowPhoto = _resolveComponent("ShowPhoto")!
  const _component_ChatContainer = _resolveComponent("ChatContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.renderComponent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.photoTaken)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_Camera, {
                  onGetPhotoBase64: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getPhotoBase64($event, _ctx.imgBase64))),
                  message: _ctx.message,
                  camType: _ctx.firstCam
                }, null, 8, ["message", "camType"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_TopBar, { class: "absolute w-full top-0 z-10" }),
                _createVNode(_component_ShowPhoto, { imgBase64: _ctx.imgBase64 }, null, 8, ["imgBase64"]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_ChatContainer, {
                      class: "absolute bottom-8 w-full",
                      rekognitionResponse: _ctx.rekognitionResponse,
                      messagesData: _ctx.messagesData,
                      personality: _ctx.personality,
                      onEndChatbot: _cache[1] || (_cache[1] = ($event: any) => (_ctx.endChatbot($event)))
                    }, null, 8, ["rekognitionResponse", "messagesData", "personality"])
                  ])
                ])
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}