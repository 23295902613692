import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-8 w-full z-20" }
const _hoisted_2 = { class: "w-4/12 pt-10 relative flex flex-col justify-center z-10" }
const _hoisted_3 = { class: "absolute w-full bottom-5" }
const _hoisted_4 = { class: "flex flex-col justify-center px-4 pt-16 z-10" }
const _hoisted_5 = { class: "text-3xl text-white font-extrabold mb-10" }
const _hoisted_6 = { class: "text-white bg-gray-100 bg-opacity-25 py-10 rounded-custom" }
const _hoisted_7 = ["placeholder", "label", "disabled"]
const _hoisted_8 = ["placeholder", "label", "disabled"]
const _hoisted_9 = ["placeholder", "label", "disabled"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { color: "red" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { color: "red" }
const _hoisted_14 = { class: "inline-flex flex-col px-100 text-left" }
const _hoisted_15 = { class: "mt-6 w-auto" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { class: "text-white text-xl text-left" }
const _hoisted_18 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShowPhoto = _resolveComponent("ShowPhoto")!
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_ShowPicture = _resolveComponent("ShowPicture")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ShowPhoto, {
      imgBase64: _ctx.imgNoBackground,
      class: "z-0 fixed mask"
    }, null, 8, ["imgBase64"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TopBar)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ShowPicture, { imgBase64: _ctx.imgBase64 }, null, 8, ["imgBase64"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "rounded-full border shadow-sm p-4 w-auto bg-white text-xl text-blue-800 font-bold uppercase",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.snapAgain()))
        }, _toDisplayString(this.t("message.snap_again")), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("message.insert_email")), 1)
    ]),
    _createElementVNode("section", {
      class: "justify-center px-4 z-10",
      style: _normalizeStyle(`opacity: ${this.isSending ? '0.3' : '1'}`)
    }, [
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: _ctx.$t('message.name_surname'),
          label: _ctx.$t('message.name_surname'),
          disabled: this.isSending,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event)),
          class: "mx-3 px-3 py-4 w-auto placeholder-white bg-transparent text-white relative rounded-full text-2xl border border-white focus:outline-none focus:ring mt-5"
        }, null, 8, _hoisted_7), [
          [_vModelText, _ctx.name]
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: _ctx.$t('message.email'),
          label: _ctx.$t('message.email'),
          disabled: this.isSending,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
          onKeyup: _cache[3] || (_cache[3] = ($event: any) => (_ctx.checkValidateMail())),
          class: "mx-3 px-3 py-4 w-auto placeholder-white bg-transparent text-white relative rounded-full text-2xl border border-white focus:outline-none focus:ring mt-5"
        }, null, 40, _hoisted_8), [
          [_vModelText, _ctx.email]
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: _ctx.$t('message.email_check'),
          label: _ctx.$t('message.email_check'),
          disabled: this.isSending,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.emailConfirm) = $event)),
          onKeyup: _cache[5] || (_cache[5] = ($event: any) => (_ctx.checkEmailConfirm())),
          class: "mx-3 px-3 py-4 w-auto placeholder-white bg-transparent text-white relative rounded-full text-2xl border border-white focus:outline-none focus:ring mt-5"
        }, null, 40, _hoisted_9), [
          [_vModelText, _ctx.emailConfirm]
        ]),
        (_ctx.isNotValid)
          ? (_openBlock(), _createElementBlock("p", _hoisted_10, [
              _createElementVNode("small", _hoisted_11, _toDisplayString(_ctx.$t("message.validate_email")), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isNotEqual)
          ? (_openBlock(), _createElementBlock("p", _hoisted_12, [
              _createElementVNode("small", _hoisted_13, _toDisplayString(_ctx.$t("message.confirm_email")), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("label", _hoisted_15, [
            _createElementVNode("input", {
              type: "checkbox",
              class: "form-checkbox mr-2 h-5 w-5",
              disabled: this.isSending,
              onClick: _cache[6] || (_cache[6] = ($event: any) => (this.consentsPrivacy = !this.consentsPrivacy))
            }, null, 8, _hoisted_16),
            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("message.personal_data_processing_radio_button")), 1)
          ])
        ]),
        _createElementVNode("button", {
          class: "rounded-full border shadow-sm p-4 w-auto bg-white text-xl text-red-600 font-bold uppercase mt-6 disabled:opacity-60",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.sendMail())),
          disabled: !this.consentsPrivacy || !this.email || !this.emailConfirm || !this.name || this.isSending
        }, _toDisplayString(this.isSending ? this.t("message.sending_photo") : this.t("message.send_photo")), 9, _hoisted_18)
      ])
    ], 4)
  ], 64))
}