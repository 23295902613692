
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { i18nArgs } from '@/types/types';

export default defineComponent({
  props: {
    message: {
      type: Object
    }
  },
  setup () {
    const t = useI18n();
    return t;
  },
  data () {
    return {
      textToShow: '',
      arguments: {} as i18nArgs
    };
  },
  created () {
    this.arguments = {
      age: this.message?.age,
      nickname: this.message?.nickname
    };
    this.textToShow = this.message?.message ? this.$t(`${this.message?.message}`, this.arguments) : this.$t(`${this.message}`, this.arguments);
  }
});
