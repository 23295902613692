
import Camera from '@/components/Camera.vue';
import { defineComponent } from 'vue';
import MessageFromBot from '@/components/MessageFromBot.vue';
import { useI18n } from 'vue-i18n';
import { mapGetters } from 'vuex';
import MessageFromUser from '@/components/MessageFromUser.vue';
import ChooseAge from '@/components/SetAge.vue';
import SetFeature from '@/components/SetFeature.vue';
import Loading from '@/components/Loading.vue';
import { getFaceRekognition } from '@/utils/apiCall';
import { formatRekognitionResponse } from '@/utils/utils';

import {
  HIDDEN_CAM,
  AGE,
  GLASSES,
  BEARD_MUSTACHE,
  RECAP,
  FEMALE_GENDER,
  CORRECT,
  WRONG,
  START_BOT,
  NO_AGE
} from '@/constants';
import { MessageNode, RekognitionResponse } from '@/types/types';

export default defineComponent({
  name: 'ChatMessages',
  props: {
    node: {
      type: Object
    },
    responses: {
      type: Object
    },
    parentNode: {
      type: Object
    },
    personality: {
      type: Number
    },
    recupSteps: {
      type: Array
    }
  },
  components: {
    MessageFromBot,
    ChooseAge,
    SetFeature,
    MessageFromUser,
    Loading,
    Camera
  },
  data () {
    return {
      STARTBOT: START_BOT,
      NO_AGE: NO_AGE,
      GLASSES: GLASSES,
      AGE: AGE,
      BEARD_MUSTACHE: BEARD_MUSTACHE,
      RECAP: RECAP,
      expanded: false,
      localResponses: this.responses,
      childToShow: this.node?.children,
      responseSetted: false,
      beardMustache: '',
      messageFromBot: '',
      noTextButton: '',
      countDown: 3,
      recupSentences: (this.recupSteps as string[]) || [],
      arguments: this.node?.nodeRecupSentence?.arguments || {},
      recupGlassesText: this.node?.nodeRecupSentence?.message || '',
      imgBase64: '',
      rekognitionResponse: {} as RekognitionResponse,
      hiddenPhotoTaken: false,
      hiddenCam: HIDDEN_CAM
    };
  },
  watch: {
    countDown () {
      if (this.countDown === 0) {
        if (this.node?.name === CORRECT || this.node?.name === WRONG) {
          this.expanded = !this.expanded;
        }
      }
    }
  },
  setup () {
    const t = useI18n();
    return t;
  },
  created () {
    console.log('Current Node', this.node);
    if (this.node?.children) {
      if (this.node?.name === START_BOT) {
        this.childToShow = this.responses?.age.rekognitionGuess
          ? this.setChildren(this.node as MessageNode, 'age')
          : this.setChildren(this.node as MessageNode, 'no_age');
        this.expanded = !this.expanded;
      }
      if (this.node?.name === NO_AGE) {
        this.expanded = !this.expanded;
      }
      if (this.parentNode?.name === AGE) {
        this.messageFromBot = this.hasMoreYear() ? this.t('message.age.response.bot.wrong[0]') : this.t('message.age.response.bot.wrong[1]');
      }
      if (this.node?.name === GLASSES) {
        this.setGlassesText();
      }
      if (this.parentNode?.name === GLASSES) {
        this.messageFromBot = this.localResponses?.glasses.eyeGlasses.hasFeature || this.localResponses?.glasses.sunGlasses.hasFeature
          ? this.t(`message.glasses.response.bot.wrong[${this.personality! > 1 ? this.personality! - 2 : this.personality}]`)
          : this.t('message.glasses.response.bot.wrong[2]');
        this.recupGlassesText =
          this.hasGlassesRekognition() ^ this.localResponses?.glasses.userResponse
            ? this.node?.nodeRecupSentence.message.without_glasses
            : this.node?.nodeRecupSentence.message.with_glasses;
      }
      if (
        this.node?.name === BEARD_MUSTACHE ||
        this.parentNode?.name === BEARD_MUSTACHE
      ) {
        this.setBeardMustache();
      }
      this.countDownTimer();

      if (this.node?.nodeRecupSentence) {
        this.arguments.age = this.localResponses?.age.userResponse;
        this.arguments.glasses_type = this.setGlassesTypeMessage();
        this.arguments.emotion = this.localResponses?.emotion.rekognitionGuess;

        this.recupSentences.push(this.t(this.recupGlassesText, this.arguments));
      }

      if (this.node?.name === RECAP && this.personality !== undefined && this.personality >= 0) {
        const recupPersonality =
          this.personality < 3 ? this.personality : this.personality - 1;
        this.recupSentences.push(
          (this.localResponses?.emotion.rekognitionGuess !== 'unknown' && this.localResponses?.emotion.rekognitionGuess !== 'UNKNOWN')
            ? this.t(`message.recap.guess.emotion[${recupPersonality}]`, { emotion: this.localResponses?.emotion.rekognitionGuess }) : ''
        );
        this.recupSentences.push(
          this.t(`message.recap.guess.endSentence[${recupPersonality}]`)
        );
        this.setRecupText();
        console.log('recap sentence', this.recupSentences);
      }
    } else {
      this.$emit('endChatbot', this.localResponses);
    }
  },
  computed: {
    userMessageResponse () {
      let message = '';
      if (this.node?.name === AGE) {
        message = this.$t('message.age.response.user', {
          age: this.localResponses?.age.userResponse
        });
      } else if (this.node?.name === GLASSES) {
        if (this.hasGlassesRekognition()) {
          message = this.localResponses?.glasses.confirmResponse
            ? this.$t('message.glasses.response.user.correct_glasses')
            : this.$t('message.glasses.response.user.wrong_glasses');
        } else {
          message = this.localResponses?.glasses.confirmResponse
            ? this.$t('message.glasses.response.user.correct_no_glasses')
            : this.$t('message.glasses.response.user.wrong_no_glasses');
        }
      } else if (this.node?.name === BEARD_MUSTACHE) {
        message = this.localResponses?.beardMustache.confirmResponse
          ? this.$t('message.yes_thank_button')
          : this.$t(`message.${this.beardMustache}.userButton`);
      } else if (this.node?.name === RECAP) {
        message = this.localResponses?.recap.confirmResponse
          ? this.$t('message.yes_confirm_button')
          : this.$t('message.no_wrong_guess');
      }
      return message;
    }
  },
  methods: {
    ...mapGetters(['nickname']),
    hasMoreYear () {
      return (
        this.localResponses?.age.userResponse > this.localResponses?.age.rekognitionGuess
      );
    },
    setRecupText () {
      this.messageFromBot = this.recupSentences.join('');
    },
    setChildren (node: MessageNode, childrenName: string) {
      return node.children.filter(function (child: { name: string }) {
        return child.name === childrenName;
      });
    },
    hasGlassesRekognition () {
      return (
        this.localResponses?.glasses.rekognitionGuess.eyeGlasses ||
        this.localResponses?.glasses.rekognitionGuess.sunGlasses
      );
    },
    setGlassesTypeMessage () {
      return this.localResponses?.glasses.rekognitionGuess.eyeGlasses
        ? this.t('message.glasses.type.eyeGlasses')
        : this.t('message.glasses.type.sunGlasses');
    },
    setGlassesType () {
      return this.localResponses?.glasses.rekognitionGuess.eyeGlasses
        ? 'eyeGlasses'
        : 'sunGlasses';
    },
    hasGlassesFeature () {
      if ((this.hasGlassesRekognition() && this.localResponses?.glasses.confirmResponse) || (!this.hasGlassesRekognition() && !this.localResponses?.glasses.confirmResponse)) {
        return true;
      } else {
        return false;
      }
    },
    setGlassesText () {
      if (this.hasGlassesRekognition()) {
        const glassesType = this.setGlassesTypeMessage();
        this.messageFromBot = this.localResponses?.age.confirmResponse ||
          this.localResponses?.age.rekognitionGuess === null
          ? this.t(`message.glasses.guess.correct_before[${this.personality}]`, {
            glasses_type: glassesType
          })
          : this.t(`message.glasses.guess.wrong_before[${this.personality}]`, {
            glasses_type: glassesType
          });
      } else {
        this.messageFromBot = this.t(
          `message.glasses.guess.no_glasses[${this.personality}]`
        );
      }
    },
    setBeardMustache () {
      if (this.node?.name === BEARD_MUSTACHE) {
        if (this.responses?.gender === FEMALE_GENDER) {
          this.beardMustache = 'none';
          this.expanded = !this.expanded;
        } else if (this.responses?.beardMustache.rekognitionGuess.beard) {
          this.beardMustache = 'beard';
          this.noTextButton = this.t('message.beard.userButton');
          this.messageFromBot = this.t(
            `message.${this.beardMustache}.guess[${this.personality}]`
          );
        } else if (this.responses?.beardMustache.rekognitionGuess.mustache) {
          this.beardMustache = 'mustache';
          this.noTextButton = this.t('message.mustache.userButton');
          this.messageFromBot = this.t(
            `message.${this.beardMustache}.guess[${this.personality}]`
          );
        } else {
          this.beardMustache = 'none';
          this.expanded = !this.expanded;
        }
      } else {
        if (this.responses?.beardMustache.rekognitionGuess.beard) {
          this.beardMustache = 'beard';
          this.noTextButton = this.t('message.mustache.userButton');
          this.recupGlassesText = this.node?.nodeRecupSentence.message.beard;
        } else if (this.responses?.beardMustache.rekognitionGuess.mustache) {
          this.beardMustache = 'mustache';
          this.noTextButton = this.t('message.mustache.userButton');
          this.recupGlassesText = this.node?.nodeRecupSentence.message.mustache;
        }
        this.messageFromBot = this.localResponses?.beardMustache.confirmResponse
          ? this.t(`message.${this.beardMustache}.response.correct`)
          : this.t(`message.${this.beardMustache}.response.wrong`);
      }
    },
    setResponse (response: { userResponse: boolean; featureType: string }): void {
      if (this.localResponses) {
        if (response.featureType === AGE) {
          this.localResponses[response.featureType].userResponse = response.userResponse;
          this.localResponses[response.featureType].confirmResponse =
            response.userResponse ===
            this.localResponses[response.featureType].rekognitionGuess;
        } else {
          this.localResponses[response.featureType].userResponse = response.userResponse;
          this.localResponses[response.featureType].confirmResponse =
            response.userResponse;
        }
        if (response.featureType === GLASSES) {
          if (this.hasGlassesFeature()) {
            this.localResponses.glasses[`${this.setGlassesType()}`].hasFeature = true;
          }
        }
        if (response.featureType === BEARD_MUSTACHE) {
          if (this.localResponses.beardMustache.rekognitionGuess.beard && this.localResponses.confirmResponse) {
            this.localResponses.beardMustache.beard.hasFeature = true;
          } else if (this.localResponses.beardMustache.rekognitionGuess.mustache && this.localResponses.confirmResponse) {
            this.localResponses.beardMustache.mustache.hasFeature = true;
          }
        }
      }
      this.setChildToShow();
      this.responseSetted = true;
    },
    setChildToShow () {
      if (this.localResponses && this.node) {
        const nameChildToShow = this.localResponses[this.node.name].confirmResponse
          ? CORRECT
          : WRONG;
        this.childToShow = this.setChildren(this.node as MessageNode, nameChildToShow);
      }
      this.expanded = !this.expanded;
    },
    countDownTimer () {
      if (this.countDown >= 0) {
        setTimeout(() => {
          if (this.countDown > 0) {
            this.countDown -= 1;
            this.countDownTimer();
          }
        }, 1000);
      }
    },
    async getPhotoBase64 (photo: { imgBase64: string }): Promise<void> {
      this.imgBase64 = photo.imgBase64;
      const formattedImgBase64 = photo.imgBase64.split(',')[1];
      const rekognitionResponse = await getFaceRekognition(formattedImgBase64);
      this.hiddenPhotoTaken = true;
      this.rekognitionResponse = formatRekognitionResponse(
        rekognitionResponse
      );
      if (this.localResponses && this.rekognitionResponse.Emotions !== 'UNKNOWN') {
        this.localResponses.emotion.rekognitionGuess = this.t(`message.emotion.${this.rekognitionResponse.Emotions?.toLowerCase()}`);
      }
    },
    endChatbot () {
      this.$emit('endChatbot', this.localResponses);
    }
  }
});
