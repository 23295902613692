import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-3xl text-white" }
const _hoisted_2 = { class: "flex justify-around items-center mx-5" }
const _hoisted_3 = ["src", "onClick"]
const _hoisted_4 = { class: "flex justify-around mt-10 mx-48" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('message.select_bg')), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (img, index) => {
        return (_openBlock(), _createElementBlock("img", {
          key: img,
          src: `${_ctx.URL_CDN}/${_ctx.PATH_S3_BG}/${img.id}`,
          onClick: ($event: any) => (_ctx.chooseBackground(index)),
          class: _normalizeClass(`borderOffset mt-10 ${img.selected ? 'borderSelected' : ''}`)
        }, null, 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "rounded-full border shadow-sm p-4 w-auto bg-white text-xl text-unipol-blue font-bold uppercase",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.snapPhoto()))
      }, _toDisplayString(_ctx.textButtonSnapAgain), 1),
      _createElementVNode("button", {
        class: "rounded-full border shadow-sm p-4 w-auto bg-white text-xl text-red-600 font-bold uppercase disabled:opacity-60",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setResponse())),
        disabled: !this.imgSelected
      }, _toDisplayString(_ctx.textButtonConfirm), 9, _hoisted_5)
    ])
  ], 64))
}