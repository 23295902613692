export const NODE_ENV = (process.env.NODE_ENV || 'development').toUpperCase();

export const AWS_REGION = process.env[`VUE_APP_AWS_REGION_${NODE_ENV}`];
export const COGNITO_USER_POOL = process.env[`VUE_APP_COGNITO_USER_POOL_${NODE_ENV}`];
export const COGNITO_USER_CLIENT = process.env[`VUE_APP_COGNITO_USER_CLIENT_${NODE_ENV}`];

export const AGE = 'age';
export const GLASSES = 'glasses';
export const BEARD_MUSTACHE = 'beardMustache';
export const RECAP = 'recap';
export const FEMALE_GENDER = 'Female';
export const DEFAULT_EMOTION = 'UNKNOWN';
export const CORRECT = 'correct';
export const WRONG = 'wrong';
export const START_BOT = 'start_bot';
export const NO_AGE = 'no_age';

export const MAX_INACTIVITY_SECONDS = 300;
export const MAX_DEFAULT_PERSONALITY = 4;
export const MAX_THREE_PERSONALITY = 3;
export const MAX_TWO_PERSONALITY = 2;
export const MAX_ONE_PERSONALITY = 1;
export const ONE_TO_EXCLUDE_ZERO = 1;

export const BASE_URL_FACE_REKOGNITION = process.env[`VUE_APP_BASE_URL_FACE_REKOGNITION_${NODE_ENV}`];
export const BASE_URL_PHOTOMONTAGE = process.env[`VUE_APP_BASE_URL_PHOTOMONTAGE_${NODE_ENV}`];
export const BASE_URL_PHOTO_NO_BG = process.env[`VUE_APP_BASE_URL_PHOTO_NO_BG_${NODE_ENV}`];
export const BASE_URL_SEND_EMAIL = process.env[`VUE_APP_BASE_URL_SEND_EMAIL_${NODE_ENV}`];
export const MIN_CONFIDENCE = 99;

export const URL_CDN = process.env[`VUE_APP_URL_CDN_${NODE_ENV}`];
export const PATH_S3_BG = 'images/backgroundImages';
export const PATH_S3_WELCOME_BG = 'images/backgroundWelcomePage';
export const NUM_BG_IMG = 4;

export const HIDDEN_CAM = 'hidden';
export const FIRST_CAM = 'first';
export const BG_CAM = 'background';

// export const API_KEY = '99PprTvCuQ4alyDm0vrbf9snZFxiESQOaGOCjv6F';
