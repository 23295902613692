import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex justify-center" }
const _hoisted_2 = {
  key: 0,
  class: "vignette"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.path !== '/sendEmail')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("img", {
      id: "base64image",
      src: this.imgBase64,
      class: "border-4"
    }, null, 8, _hoisted_3)
  ]))
}