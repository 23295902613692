
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'showPhoto',
  props: {
    imgBase64: {
      type: String
    }
  }
});
