import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatMessages = _resolveComponent("ChatMessages")!
  const _component_ChooseBackground = _resolveComponent("ChooseBackground")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_ChatMessages, {
        node: _ctx.messagesData,
        responses: _ctx.responses,
        personality: _ctx.personality,
        onEndChatbot: _cache[0] || (_cache[0] = ($event: any) => (_ctx.endChatbot($event, _ctx.userFeatures))),
        class: _normalizeClass(!_ctx.endChat ? '' : 'hidden ')
      }, null, 8, ["node", "responses", "personality", "class"])
    ]),
    (_ctx.endChat)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ChooseBackground, { personality: _ctx.personality }, null, 8, ["personality"])
        ]))
      : _createCommentVNode("", true)
  ]))
}