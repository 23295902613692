import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "basis-1/2 px-10" }
const _hoisted_2 = { class: "basis-1/2 px-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex flex-row justify-center align-middle py-1",
    style: _normalizeStyle(_ctx.setDisplay)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        type: "button",
        class: "inline-flex justify-center rounded-full border shadow-sm p-4 w-auto bg-white text-xl text-blue-600 font-bold uppercase",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setResponse(false)))
      }, _toDisplayString(_ctx.noTextButtonText), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        class: "inline-flex justify-center rounded-full border shadow-sm p-4 w-auto bg-white text-xl text-red-600 font-bold uppercase",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setResponse(true)))
      }, _toDisplayString(_ctx.yesTextButtonText), 1)
    ])
  ], 4))
}