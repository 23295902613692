import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Policy from '@/views/Policy.vue';
import Home from '@/views/WelcomePage.vue';
import ChatBot from '@/views/ChatBot.vue';
import ChooseBackground from '@/views/ChooseBackground.vue';
import SendEmail from '@/views/SendEmail.vue';
import EndPage from '@/views/EndPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'welcomePage',
    component: Home
  },
  {
    path: '/policy',
    name: 'policy',
    component: Policy
  },
  {
    path: '/chatbot',
    name: 'chatbot',
    component: ChatBot
  },
  {
    path: '/chooseBackground',
    name: 'chooseBackground',
    component: ChooseBackground
  },
  {
    path: '/sendEmail',
    name: 'sendEmail',
    component: SendEmail
  },
  {
    path: '/goodbye',
    name: 'endPage',
    component: EndPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
