import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageFromBot = _resolveComponent("MessageFromBot")!
  const _component_ChooseAge = _resolveComponent("ChooseAge")!
  const _component_SetFeature = _resolveComponent("SetFeature")!
  const _component_MessageFromUser = _resolveComponent("MessageFromUser")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Camera = _resolveComponent("Camera")!
  const _component_ChatMessages = _resolveComponent("ChatMessages", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.node.children && _ctx.node.name != _ctx.STARTBOT && _ctx.node.name != _ctx.NO_AGE)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.countDown === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  _createVNode(_component_MessageFromBot, {
                    message: _ctx.node.messageFromBot ? _ctx.node.messageFromBot : _ctx.messageFromBot
                  }, null, 8, ["message"]),
                  (_ctx.node.name === _ctx.AGE)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_ChooseAge, {
                          rekognitionAge: _ctx.responses.age.rekognitionGuess,
                          featureType: _ctx.node.name,
                          onSetResponse: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setResponse($event, _ctx.userResponse)))
                        }, null, 8, ["rekognitionAge", "featureType"])
                      ]))
                    : _createCommentVNode("", true),
                  (
              _ctx.node.name === _ctx.GLASSES || _ctx.node.name === _ctx.BEARD_MUSTACHE || _ctx.node.name === _ctx.RECAP
            )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_SetFeature, {
                          noTextButton: _ctx.node.noTextButton ? _ctx.node.noTextButton : _ctx.noTextButton,
                          yesTextButton: _ctx.node.yesTextButton,
                          featureType: _ctx.node.name,
                          onSetResponse: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setResponse($event, _ctx.userResponse)))
                        }, null, 8, ["noTextButton", "yesTextButton", "featureType"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.responseSetted)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_MessageFromUser, { message: _ctx.userMessageResponse }, null, 8, ["message"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_Loading, { class: "w-30 text-left pl-20 py-3" })
              ])),
          (_ctx.parentNode.name === _ctx.GLASSES)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_Camera, {
                  onGetPhotoBase64: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getPhotoBase64($event, _ctx.imgBase64))),
                  message: "",
                  countDown: 1,
                  countDownToSnapPhoto: 1,
                  camType: _ctx.hiddenCam,
                  class: "hidden"
                }, null, 8, ["camType"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.expanded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.childToShow, (child) => {
            return (_openBlock(), _createBlock(_component_ChatMessages, {
              key: child,
              node: child,
              parentNode: _ctx.node,
              parentResponseSettet: _ctx.responseSetted,
              responses: _ctx.localResponses,
              personality: _ctx.personality,
              recupSteps: _ctx.recupSentences,
              onEndChatbot: _cache[3] || (_cache[3] = ($event: any) => (_ctx.endChatbot($event, _ctx.localResponses)))
            }, null, 8, ["node", "parentNode", "parentResponseSettet", "responses", "personality", "recupSteps"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}