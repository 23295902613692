
import Camera from '@/components/Camera.vue';
import ShowPhoto from '@/components/ShowPhoto.vue';
import ChatContainer from '@/components/ChatContainer.vue';
import TopBar from '@/components/TopBar.vue';

import { defineComponent } from 'vue';
import { setChatbotData } from '@/utils/chatbotData';
import { mapGetters } from 'vuex';
import { RekognitionResponse } from '@/types/types';
import { useI18n } from 'vue-i18n';
import {
  MAX_DEFAULT_PERSONALITY,
  FIRST_CAM
} from '@/constants';
import { getFaceRekognition } from '@/utils/apiCall';
import { formatRekognitionResponse } from '@/utils/utils';

export default defineComponent({
  components: {
    Camera,
    TopBar,
    ShowPhoto,
    ChatContainer
  },
  name: 'chatbot',
  data () {
    return {
      photoTaken: false,
      renderComponent: true,
      restartSession: false,
      imgBase64: '',
      rekognitionResponse: {} as RekognitionResponse,
      messagesData: {},
      personality: 0,
      message: '',
      endChat: false,
      firstCam: FIRST_CAM
    };
  },
  created () {
    this.personality = Math.floor(Math.random() * MAX_DEFAULT_PERSONALITY);
    this.messageHelloUser();
  },
  setup () {
    const t = useI18n();
    return t;
  },
  methods: {
    ...mapGetters(['nickname']),
    messageHelloUser () {
      this.message = this.t(`message.hello_user[${this.personality}]`, {
        nickname: this.nickname()
      });
    },
    async getPhotoBase64 (photo: { imgBase64: string }): Promise<void> {
      this.imgBase64 = photo.imgBase64;
      const formattedImgBase64 = photo.imgBase64.split(',')[1];
      const rekognitionResponse = await getFaceRekognition(formattedImgBase64);
      this.rekognitionResponse = formatRekognitionResponse(
        rekognitionResponse
      );
      // call to rekognition
      if (this.rekognitionResponse?.Soundness) {
        this.messagesData = setChatbotData(
          this.rekognitionResponse,
          this.nickname(),
          this.personality
        );
        this.photoTaken = true;
      } else {
        this.message = this.t('message.take_photo_again');
        this.renderComponent = false;
        this.$nextTick(() => {
          this.renderComponent = true;
        });
      }
    },
    endChatbot () {
      this.endChat = true;
    }
  }
});
