
import Camera from 'simple-vue-camera';
import { defineComponent, ref } from 'vue';
import MessageFromBot from '@/components/MessageFromBot.vue';
import TopBar from '@/components/TopBar.vue';
import { mapActions } from 'vuex';
import { getImgPhotoNoBackground } from '@/utils/apiCall';
import { HIDDEN_CAM, FIRST_CAM, BG_CAM } from '@/constants';

export default defineComponent({
  data () {
    return {
      snapPhoto: false,
      countDownLocal: this.countDown,
      countDownToSnapPhotoLocal: this.countDownToSnapPhoto,
      hiddenCam: HIDDEN_CAM,
      firstCam: FIRST_CAM,
      bgCam: BG_CAM
    };
  },
  props: {
    message: {
      type: Object
    },
    camType: {
      type: String
    },
    countDown: {
      type: Number,
      default: 5
    },
    countDownToSnapPhoto: {
      type: Number,
      default: 3
    }
  },
  components: {
    Camera,
    MessageFromBot,
    TopBar
  },
  setup () {
    const camera = ref<InstanceType<typeof Camera>>();

    const imgBase64 = ref();

    const snapshot = async () => {
      const blob = await camera.value?.snapshot(
        { width: 1080, height: 1920 },
        'image/png'
      );

      var reader = new FileReader();
      reader.onloadend = function () {
        var base64data = reader.result;
        imgBase64.value = base64data;
      };
      if (blob) {
        reader.readAsDataURL(blob);
      }
    };

    return {
      camera,
      snapshot,
      imgBase64
    };
  },
  created () {
    this.countDownTimer(this.snapPhoto);
  },
  watch: {
    countDownLocal () {
      if (this.countDownLocal === 0) {
        this.snapPhoto = true;
        if (this.countDownToSnapPhotoLocal > 0) {
          this.countDownTimer(this.snapPhoto);
        }
      }
    },
    countDownToSnapPhotoLocal () {
      if (this.countDownToSnapPhotoLocal === 0) {
        this.snapshot();
      }
    },
    async imgBase64 () {
      if (this.camType === this.firstCam) {
        this.setPictureTaken(this.imgBase64);
        this.$emit('getPhotoBase64', { imgBase64: this.imgBase64 });

        const photoNoBackground = await getImgPhotoNoBackground(
          this.imgBase64.split(',')[1]
        );
        this.setPictureNoBackground(photoNoBackground);
      }

      if (this.camType === this.hiddenCam) {
        this.$emit('getPhotoBase64', { imgBase64: this.imgBase64 });
      }

      if (this.camType === this.bgCam) {
        this.setPictureTaken(this.imgBase64);

        this.$emit('setLoading', true);
        const photoNoBackground = await getImgPhotoNoBackground(
          this.imgBase64.split(',')[1]
        );
        this.setPictureNoBackground(photoNoBackground);
        this.$emit('setLoading', false);

        this.$emit('getPhotoBase64', { imgBase64: this.imgBase64 });
      }
    }
  },
  methods: {
    ...mapActions(['setPictureTaken', 'setPictureNoBackground']),
    countDownTimer (snapPhoto: boolean) {
      if (this.countDownLocal >= 0 || this.countDownToSnapPhotoLocal >= 0) {
        setTimeout(() => {
          if (!snapPhoto && this.countDownLocal > 0) {
            this.countDownLocal -= 1;
            this.countDownTimer(snapPhoto);
          } else if (snapPhoto && this.countDownToSnapPhotoLocal > 0) {
            this.countDownToSnapPhotoLocal -= 1;
            this.countDownTimer(snapPhoto);
          }
        }, 1000);
      }
    }
  }
});
