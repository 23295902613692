import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'tailwindcss/tailwind.css';
import { i18n } from './plugins/i18n';
import Amplify from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .mount('#app');
