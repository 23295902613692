
import TopBar from '@/components/TopBar.vue';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'policy',
  data () {
    return {
      consentsPrivacy: false,
      nickname: '',
      checkErrors: false,
      restartSession: false
    };
  },
  components: {
    TopBar
  },
  computed: {
    nicknameError (): boolean {
      return this.nickname.length < 3 && this.checkErrors;
    }
  },
  methods: {
    ...mapActions(['setNickname']),
    async startGame () {
      await this.setNickname(this.nickname);
      this.checkErrors = true;
      this.$router.push({
        name: 'chatbot'
      });
    }
  }
});
