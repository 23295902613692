
import { defineComponent } from 'vue';
import ChatMessages from '@/components/ChatMessages.vue';
import ChooseBackground from '@/components/EndChatBot.vue';
import { useI18n } from 'vue-i18n';
import { mapActions, mapGetters } from 'vuex';
import {
  MAX_DEFAULT_PERSONALITY,
  MAX_THREE_PERSONALITY,
  MAX_TWO_PERSONALITY
} from '@/constants';

export default defineComponent({
  name: 'ChatContainer',
  props: {
    rekognitionResponse: {
      type: Object
    },
    messagesData: {
      type: Object
    },
    personality: {
      type: Number
    }
  },
  components: {
    ChatMessages,
    ChooseBackground
  },
  created () {
    this.responses.age.rekognitionGuess = this.rekognitionResponse?.Age;
    this.responses.glasses.rekognitionGuess.eyeGlasses = this.rekognitionResponse?.Eyeglasses;
    this.responses.glasses.rekognitionGuess.sunGlasses = this.rekognitionResponse?.Sunglasses;
    this.responses.beardMustache.rekognitionGuess.beard = this.rekognitionResponse?.Beard;
    this.responses.beardMustache.rekognitionGuess.mustache = this.rekognitionResponse?.Mustache;
    this.responses.emotion.rekognitionGuess = this.getEmotion(
      this.rekognitionResponse?.Emotions
    );
    this.responses.gender = this.rekognitionResponse?.Gender;
  },
  setup () {
    const t = useI18n();
    return t;
  },
  data () {
    return {
      endChat: false,
      responses: {
        age: {
          rekognitionGuess: 0,
          userResponse: 0,
          confirmResponse: false
        },
        glasses: {
          rekognitionGuess: {
            eyeGlasses: false,
            sunGlasses: false
          },
          userResponse: false,
          confirmResponse: false,
          eyeGlasses: {
            hasFeature: false
          },
          sunGlasses: {
            hasFeature: false
          }
        },
        beardMustache: {
          rekognitionGuess: {
            beard: false,
            mustache: false
          },
          userResponse: false,
          confirmResponse: false,
          beard: {
            hasFeature: false
          },
          mustache: {
            hasFeature: false
          }
        },
        emotion: {
          rekognitionGuess: ''
        },
        recap: {
          userResponse: false,
          confirmResponse: false
        },
        gender: ''
      },
      numPhrase: {
        default: Math.floor(Math.random() * MAX_DEFAULT_PERSONALITY),
        ageWrong: Math.floor(Math.random() * MAX_TWO_PERSONALITY),
        glassesWrong: Math.floor(Math.random() * MAX_THREE_PERSONALITY)
      }
    };
  },
  methods: {
    ...mapGetters(['nickname']),
    ...mapActions(['setUserFeatures']),
    getEmotion (rekognitionEmotion: string) {
      return this.t(`message.emotion.${rekognitionEmotion.toLowerCase()}`);
    },
    endChatbot (userFeatures: any) {
      this.endChat = true;
      this.setUserFeatures({
        Age: userFeatures.age.userResponse,
        Eyeglasses: userFeatures.glasses.eyeGlasses.hasFeature,
        Sunglasses: userFeatures.glasses.sunGlasses.hasFeature,
        Gender: userFeatures.gender,
        Beard: userFeatures.beardMustache.beard.hasFeature,
        Mustache: userFeatures.beardMustache.mustache.hasFeature,
        Emotions: userFeatures.emotion.rekognitionGuess
      });
    }
  }
});
