
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'chat',
  setup () {
    const t = useI18n();
    return t;
  },
  data () {
    return {
      ageByUser: this.rekognitionAge || 0,
      getDisplay: true
    };
  },
  props: {
    featureType: {
      type: String
    },
    rekognitionAge: {
      type: Number
    }
  },
  computed: {
    ageButton () {
      return this.rekognitionAge === this.ageByUser ? this.t('message.confirm_button') : this.t('message.correction_button');
    },
    setDisplay () {
      return this.getDisplay ? '' : 'display: none';
    }
  },
  methods: {
    increaseAge ():void {
      this.ageByUser += 1;
    },
    decreaseAge ():void {
      this.ageByUser -= 1;
    },
    setResponse ():void {
      this.$emit('setResponse', { userResponse: this.ageByUser, featureType: this.featureType || null });
      this.getDisplay = false;
    }
  }
});
