
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    message: {
      type: String
    }
  },
  methods: {
    ...mapGetters(['nickname']),
    getFirstLetter (value: string) {
      return value.charAt(0).toUpperCase();
    }
  },
  computed: {
    firstLetter () {
      return this.getFirstLetter(this.nickname());
    }
  }
});
