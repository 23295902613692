import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-9/12 fixed bottom-64" }
const _hoisted_2 = { class: "text-3xl text-white font-extrabold mb-10" }
const _hoisted_3 = { class: "text-xl text-white font-extrabold mb-10 opacity-60" }
const _hoisted_4 = { class: "text-5xl text-white font-extrabold mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShowPhoto = _resolveComponent("ShowPhoto")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ShowPhoto, {
      imgBase64: _ctx.imgNoBackground,
      class: "z-0 fixed mask"
    }, null, 8, ["imgBase64"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("message.check_mail_box")), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("message.subtitle_check_email")), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("message.good_day")), 1),
      _createElementVNode("button", {
        class: "bg-white hover:bg-gray-50 font-bold py-5 px-10 rounded-full text-2xl text-red-500 uppercase disabled:opacity-60",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.restartGame()))
      }, _toDisplayString(_ctx.$t("message.restartGame")), 1)
    ])
  ], 64))
}