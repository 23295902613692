
import { defineComponent } from 'vue';
import { Auth } from 'aws-amplify';

export default defineComponent({
  name: 'welcome_page',
  data () {
    return {};
  },
  mounted () {
    Auth.currentAuthenticatedUser()
      .then(user => {
        (window as any).utag.view({
          installazione_dominio: user.attributes['custom:Codice'],
          installazione_codice: user.attributes['custom:Dominio']
        });
      })
      .catch(err => console.error(err));
  },
  methods: {
    startGame () {
      this.$router.push({
        path: '/policy'
      });
    }
  }
});
