/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { BASE_URL_FACE_REKOGNITION, BASE_URL_PHOTOMONTAGE, BASE_URL_PHOTO_NO_BG, MIN_CONFIDENCE, BASE_URL_SEND_EMAIL } from '@/constants';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

async function getCurrentSession () {
  const authSession:CognitoUserSession = await Auth.currentSession();
  const bearerToken = authSession.getIdToken();
  return bearerToken.getJwtToken();
}

export async function getImgPhotoMontage (imgBase64: string, idBackground: string) {
  try {
    const token = await getCurrentSession();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const response = await axios.post(BASE_URL_PHOTOMONTAGE!, {
      Image: {
        Bytes: imgBase64
      },
      Painting_id: idBackground
    }, headers);

    return response.data;
  } catch (errors) {
    console.error(errors);
  }
}

export async function getImgPhotoNoBackground (imgBase64: string) {
  try {
    const token = await getCurrentSession();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const response = await axios.post(BASE_URL_PHOTO_NO_BG!, {
      Image: {
        Bytes: imgBase64
      }
    }, headers);

    return response.data;
  } catch (errors) {
    console.error(errors);
  }
}

export async function getFaceRekognition (imgBase64: string) {
  try {
    const token = await getCurrentSession();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const response = await axios.post(BASE_URL_FACE_REKOGNITION!, {
      Image: {
        Bytes: imgBase64
      },
      MinConfidence: MIN_CONFIDENCE
    }, headers);

    return response.data;
  } catch (errors) {
    console.error(errors);
  }
}

export async function sendEmail (params: any) {
  try {
    const token = await getCurrentSession();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const response = await axios.post(
      BASE_URL_SEND_EMAIL!,
      params,
      headers
    );

    return response.data;
  } catch (errors) {
    console.error(errors);
  }
}
