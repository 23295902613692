import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end px-16 py-1" }
const _hoisted_2 = { class: "text-right pr-3 pt-4 text-white text-2xl" }
const _hoisted_3 = { class: "m-1 mr-2 w-12 h-12 relative flex justify-center items-center rounded-full bg-white text-xl text-red-500 uppercase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(this.message), 1)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(this.firstLetter), 1)
  ]))
}