
import { defineComponent } from 'vue';
import { PATH_S3_BG, URL_CDN, BG_CAM } from '@/constants';
import { useI18n } from 'vue-i18n';

import TopBar from '@/components/TopBar.vue';
import Camera from '@/components/Camera.vue';
import ShowPhoto from '@/components/ShowPhoto.vue';
import CarouselBackground from '@/components/CarouselBackground.vue';
import Loading from '@/components/Loading.vue';
import { getImgPhotoMontage } from '@/utils/apiCall';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'chooseBackground',
  components: {
    TopBar,
    Camera,
    ShowPhoto,
    CarouselBackground,
    Loading
  },
  data () {
    return {
      restartSession: false,
      photoTaken: true,
      imgBase64: this.pictureTaken(),
      transparentBackground: `data:image/png;base64,${this.pictureNoBackground()}`,
      formattedImg: '',
      endLoading: false,
      isLoading: false,
      selectedButton: false,
      backgroundImage: 'rectangle.png',
      PATH_S3_BG: PATH_S3_BG,
      URL_CDN: URL_CDN,
      bgCam: BG_CAM
    };
  },
  setup () {
    const t = useI18n();
    return t;
  },
  async mounted () {
    this.getPhotoBase64({ imgBase64: this.imgBase64 });
  },
  methods: {
    ...mapActions(['setImgWithBackground', 'setImgNoBackground']),
    ...mapGetters(['pictureTaken', 'pictureNoBackground']),
    getPhotoBase64 (photo: { imgBase64: string }) {
      this.imgBase64 = photo.imgBase64;
      this.formattedImg = photo.imgBase64.split(',')[1];
      this.photoTaken = true;
      this.setImgNoBackground(this.imgBase64);
      this.transparentBackground = `data:image/png;base64,${this.pictureNoBackground()}`;
    },
    snapAgain () {
      this.photoTaken = false;
    },
    changeBackground (backgroundSelected: string) {
      this.backgroundImage = backgroundSelected;
    },
    setLoading (value: boolean) {
      this.isLoading = value;
    },
    async getPhotoWithBackground (backgroundSelected: string) {
      this.selectedButton = true;
      const photoMontage = await getImgPhotoMontage(
        this.formattedImg,
        backgroundSelected
      );
      await this.setImgWithBackground(photoMontage).then(() => {
        this.endLoading = true;
      });
      this.$router.push({
        name: 'sendEmail',
        path: '/sendEmail'
      });
    }
  }
});
