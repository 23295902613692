import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "bg-unipol-blue" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "relative h-screen"
}
const _hoisted_4 = {
  key: 1,
  class: "relative h-screen"
}
const _hoisted_5 = { class: "bg-inherit w-full h-100 absolute bottom-10 overflow-scroll z-10" }
const _hoisted_6 = {
  key: 2,
  class: "absolute left-1/2 top-1/2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_ShowPhoto = _resolveComponent("ShowPhoto")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Camera = _resolveComponent("Camera")!
  const _component_CarouselBackground = _resolveComponent("CarouselBackground")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.photoTaken)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_TopBar, { class: "absolute w-full top-0 z-10" }),
                _createVNode(_component_ShowPhoto, {
                  imgBase64: this.pictureTaken()
                }, null, 8, ["imgBase64"]),
                _createVNode(_component_Loading, { class: "absolute left-1/2 top-1/2" })
              ]))
            : (_openBlock(), _createBlock(_component_Camera, {
                key: 1,
                onGetPhotoBase64: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getPhotoBase64($event, _ctx.imgBase64))),
                onSetLoading: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setLoading($event))),
                message: this.$t(`message.photo_agreement.bot.response.confirm`),
                camType: _ctx.bgCam
              }, null, 8, ["message", "camType"]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.selectedButton && !_ctx.endLoading ? 'opacity-60' : '')
          }, [
            _createVNode(_component_TopBar, { class: "absolute w-full top-0 z-10" }),
            _createElementVNode("div", {
              class: "grid bg-cover bg-center h-screen",
              style: _normalizeStyle(`background-image: url('${_ctx.URL_CDN}/${_ctx.PATH_S3_BG}/${_ctx.backgroundImage}')`)
            }, [
              _createVNode(_component_ShowPhoto, {
                class: "col-span-full row-span-full",
                imgBase64: _ctx.transparentBackground
              }, null, 8, ["imgBase64"])
            ], 4),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_CarouselBackground, {
                onGetPhotoWithBackground: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getPhotoWithBackground($event, _ctx.imgSelected))),
                onSnapAgain: _cache[3] || (_cache[3] = ($event: any) => (_ctx.snapAgain())),
                onChangeBackground: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeBackground($event)))
              })
            ])
          ], 2)
        ])),
    (_ctx.selectedButton && !_ctx.endLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_Loading)
        ]))
      : _createCommentVNode("", true)
  ]))
}