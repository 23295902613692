import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex flex-col justify-center px-4 pt-20" }
const _hoisted_3 = { class: "text-3xl text-white font-extrabold mb-10" }
const _hoisted_4 = { class: "flex flex-col px-100 md:overflow-scroll h-72" }
const _hoisted_5 = { class: "text-white text-xl p-8 text-left bg-gray-100 bg-opacity-25 customRadius" }
const _hoisted_6 = { class: "inline-flex flex-col px-100 text-left" }
const _hoisted_7 = { class: "mt-6" }
const _hoisted_8 = { class: "text-white text-xl text-left" }
const _hoisted_9 = { class: "flex flex-col justify-center" }
const _hoisted_10 = { class: "text-3xl text-white font-extrabold mt-20" }
const _hoisted_11 = { class: "pt-0" }
const _hoisted_12 = ["placeholder", "label"]
const _hoisted_13 = {
  key: 0,
  class: "text-l font-extrabold border-red-600 focus:border-red-600 text-red-600 mb-20"
}
const _hoisted_14 = { class: "flex justify-center" }
const _hoisted_15 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopBar),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("message.personal_data_processing_title")), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("message.personal_data_processing_text")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, [
          _createElementVNode("input", {
            type: "checkbox",
            class: "form-checkbox mr-2 h-5 w-5",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (this.consentsPrivacy = !this.consentsPrivacy))
          }),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("message.personal_data_processing_radio_button")), 1)
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_9, [
      _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t("message.nickname_title")), 1),
      _createElementVNode("div", _hoisted_11, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: _ctx.$t('message.nickname_label'),
          label: _ctx.$t('message.nickname_label'),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.nickname) = $event)),
          class: _normalizeClass(["px-8 py-4 w-5/12 placeholder-gray-400 bg-transparent text-white relative rounded-full text-2xl border border-gray-400 focus:outline-none focus:ring mt-5", {
            'border-gray-400': !this.nicknameError,
            'focus:border-blue-900': !this.nicknameError,
            'border-red-600': this.nicknameError,
            'focus:border-red-600': this.nicknameError,
            'mb-20': !this.nicknameError,
            'mb-3': this.nicknameError,
            filled: this.nicknameError,
          }])
        }, null, 10, _hoisted_12), [
          [_vModelText, this.nickname]
        ]),
        (this.nicknameError)
          ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.$t("message.nickname_error")), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", _hoisted_14, [
      _createElementVNode("button", {
        class: "bg-white hover:bg-gray-50 py-5 px-10 rounded-full text-2xl text-red-500 uppercase disabled:opacity-60 btn-font",
        disabled: !this.consentsPrivacy || !this.nickname,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.startGame()))
      }, _toDisplayString(_ctx.$t("message.welcome_button")), 9, _hoisted_15)
    ])
  ]))
}