import { Auth } from 'aws-amplify';

export const signIn = async (email: string, password: string): Promise<any> => {
  return await Auth.signIn(email as string, password);
};

export const signOut = async (): Promise<any> => {
  localStorage.clear();
  return Auth.signOut();
};

export const getCurrentUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {}
};
