import { createStore } from 'vuex';

const store = createStore({
  state: {
    nickname: '',
    imgWithBackground: '',
    imgNoBackground: '',
    userFeatures: {},
    pictureTaken: '',
    pictureNoBackground: ''
  },
  mutations: {
    setNickname (state, nickname) {
      state.nickname = nickname;
    },
    setImgWithBackground (state, imgWithBackground) {
      state.imgWithBackground = imgWithBackground;
    },
    setImgNoBackground (state, imgNoBackground) {
      state.imgNoBackground = imgNoBackground;
    },
    setUserFeatures (state, userFeatures) {
      state.userFeatures = userFeatures;
    },
    setPictureTaken (state, pictureTaken) {
      state.pictureTaken = pictureTaken;
    },
    setPictureNoBackground (state, pictureNoBackground) {
      state.pictureNoBackground = pictureNoBackground;
    }
  },
  actions: {
    setNickname ({ commit }, nickname) {
      commit('setNickname', nickname);
    },
    setImgWithBackground ({ commit }, imgWithBackground) {
      commit('setImgWithBackground', imgWithBackground);
    },
    setImgNoBackground ({ commit }, imgNoBackground) {
      commit('setImgNoBackground', imgNoBackground);
    },
    setUserFeatures ({ commit }, userFeatures) {
      commit('setUserFeatures', userFeatures);
    },
    setPictureTaken ({ commit }, pictureTaken) {
      commit('setPictureTaken', pictureTaken);
    },
    setPictureNoBackground ({ commit }, pictureNoBackground) {
      commit('setPictureNoBackground', pictureNoBackground);
    }
  },
  getters: {
    nickname: (state) => {
      return state.nickname;
    },
    imgWithBackground: (state) => {
      return state.imgWithBackground;
    },
    imgNoBackground: (state) => {
      return state.imgNoBackground;
    },
    userFeatures: (state) => {
      return state.userFeatures;
    },
    pictureTaken: (state) => {
      return state.pictureTaken;
    },
    pictureNoBackground: (state) => {
      return state.pictureNoBackground;
    }
  }
});

export default store;
