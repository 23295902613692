
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { mapGetters } from 'vuex';

import TopBar from '@/components/TopBar.vue';
import ShowPicture from '@/components/ShowPicture.vue';
import ShowPhoto from '@/components/ShowPhoto.vue';
import { sendEmail } from '@/utils/apiCall';

export default defineComponent({
  name: 'sendEmail',
  components: {
    TopBar,
    ShowPicture,
    ShowPhoto
  },
  data () {
    return {
      restartSession: false,
      photoTaken: false,
      isNotValid: false,
      isNotEqual: false,
      isSending: false,
      imgBase64: `data:image/png;base64,${this.imgWithBackground()}`,
      name: '',
      email: '',
      emailConfirm: '',
      consentsPrivacy: false
    };
  },
  setup () {
    const t = useI18n();
    return t;
  },
  computed: {
    ...mapGetters(['imgNoBackground'])
  },
  methods: {
    ...mapGetters(['imgWithBackground', 'userFeatures']),
    snapAgain () {
      this.$router.push({
        name: 'chooseBackground',
        path: '/chooseBackground'
      });
    },
    async sendMail () {
      this.isSending = true;
      const params = {
        name: this.name,
        email: this.email,
        image: this.imgWithBackground(),
        Age: this.userFeatures().Age,
        Eyeglasses: this.userFeatures().Eyeglasses,
        Sunglasses: this.userFeatures().Sunglasses,
        Gender: this.userFeatures().Gender,
        Beard: this.userFeatures().Beard,
        Mustache: this.userFeatures().Mustache,
        Emotions: this.userFeatures().Emotions

      };

      const validateMail = String(this.email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

      if (validateMail && this.emailConfirm === this.email) {
        this.isNotEqual = false;
        this.isNotValid = false;
        await sendEmail(params);
        this.$router.push({
          name: 'endPage',
          path: '/goodbye'
        });
      } else {
        this.isSending = false;
        this.isNotValid = !validateMail;
        this.isNotEqual = this.emailConfirm !== this.email;
      }
    },
    checkValidateMail () {
      const validateMail = String(this.email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      if (validateMail) {
        this.isNotValid = false;
      }
    },
    checkEmailConfirm () {
      this.isNotEqual = this.emailConfirm !== this.email;
    }
  }
});
