import { FEMALE_GENDER, MAX_THREE_PERSONALITY } from '@/constants';
import { RekognitionResponse } from '@/types/types';

function setGlassesButtonText (rekognitionResponse: RekognitionResponse, type: string) {
  const hasGlasses = rekognitionResponse.Eyeglasses || rekognitionResponse.Sunglasses;
  let textButton = '';
  if (hasGlasses) {
    textButton = type === 'yesButton' ? 'message.yes_button' : 'message.glasses.userButton.noGlasses';
  } else {
    textButton = type === 'yesButton' ? 'message.yes_button' : 'message.glasses.userButton.yesGlasses';
  }
  return textButton;
}

function showBeardMustacheChildren (rekognitionResponse: RekognitionResponse) {
  return !(rekognitionResponse.Gender === FEMALE_GENDER || (rekognitionResponse.Beard === false && rekognitionResponse.Mustache === false));
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function setChatbotData (rekognitionResponse: RekognitionResponse, nickname: string, personality: number) {
  const recupPersonality = personality < 3 ? personality : personality - 1;
  return {
    name: 'start_bot',
    nodeRecupSentence: {
      message: `message.recap.guess.startSentence[${recupPersonality}]`,
      arguments: {
        nickname
      }
    },
    children: [{
      name: 'no_age',
      children: [
        {
          name: 'glasses',
          yesTextButton: { message: setGlassesButtonText(rekognitionResponse, 'yesButton') },
          noTextButton: { message: setGlassesButtonText(rekognitionResponse, 'noButton') },
          children: [
            {
              name: 'correct',
              nodeRecupSentence: {
                message: {
                  with_glasses: `message.recap.guess.glasses.with_glasses[${recupPersonality}]`,
                  without_glasses: `message.recap.guess.glasses.without_glasses[${recupPersonality}]`
                }
              },
              messageFromBot: { message: 'message.glasses.response.bot.correct' },
              children: showBeardMustacheChildren(rekognitionResponse) ? [
                {
                  name: 'beardMustache',
                  yesTextButton: { message: 'message.yes_thank_button' },
                  children: [
                    {
                      name: 'correct',
                      nodeRecupSentence: {
                        message: {
                          beard: `message.recap.guess.beard[${recupPersonality}]`,
                          mustache: `message.recap.guess.mustache[${recupPersonality}]`
                        }
                      },
                      children: [
                        {
                          name: 'recap',
                          yesTextButton: { message: 'message.yes_confirm_button' },
                          noTextButton: { message: 'message.no_wrong_guess' },
                          children: [
                            {
                              name: 'correct',
                              messageFromUser: { message: 'message.yes_confirm_button' }
                            },
                            {
                              name: 'wrong',
                              messageFromUser: { message: 'message.no_wrong_guess' }
                            }
                          ]
                        }
                      ]
                    },
                    {
                      name: 'wrong',
                      nodeRecupSentence: {
                        message: {
                          beard: '',
                          mustache: ''
                        }
                      },
                      children: [
                        {
                          name: 'recap',
                          yesTextButton: { message: 'message.yes_confirm_button' },
                          noTextButton: { message: 'message.no_wrong_guess' },
                          children: [
                            {
                              name: 'correct',
                              messageFromUser: { message: 'message.yes_confirm_button' }
                            },
                            {
                              name: 'wrong',
                              messageFromUser: { message: 'message.no_wrong_guess' }
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ] : [
                {
                  name: 'recap',
                  yesTextButton: { message: 'message.yes_confirm_button' },
                  noTextButton: { message: 'message.no_wrong_guess' },
                  children: [
                    {
                      name: 'correct',
                      messageFromUser: { message: 'message.yes_confirm_button' }
                    },
                    {
                      name: 'wrong',
                      messageFromUser: { message: 'message.no_wrong_guess' }
                    }
                  ]
                }
              ]
            },
            {
              name: 'wrong',
              nodeRecupSentence: {
                message: {
                  with_glasses: `message.recap.guess.glasses.with_glasses[${recupPersonality}]`,
                  without_glasses: `message.recap.guess.glasses.without_glasses[${recupPersonality}]`
                }
              },
              messageFromUser: { message: 'message.glasses.response.user.wrong' },
              children: showBeardMustacheChildren(rekognitionResponse) ? [
                {
                  name: 'beardMustache',
                  yesTextButton: { message: 'message.yes_thank_button' },
                  children: [
                    {
                      name: 'correct',
                      nodeRecupSentence: {
                        message: {
                          beard: `message.recap.guess.beard[${recupPersonality}]`,
                          mustache: `message.recap.guess.mustache[${recupPersonality}]`
                        }
                      },
                      children: [
                        {
                          name: 'recap',
                          yesTextButton: { message: 'message.yes_confirm_button' },
                          noTextButton: { message: 'message.no_wrong_guess' },
                          children: [
                            {
                              name: 'correct',
                              messageFromUser: { message: 'message.yes_confirm_button' }
                            },
                            {
                              name: 'wrong',
                              messageFromUser: { message: 'message.no_wrong_guess' }
                            }
                          ]
                        }
                      ]
                    },
                    {
                      name: 'wrong',
                      nodeRecupSentence: {
                        message: {
                          beard: '',
                          mustache: ''
                        }
                      },
                      children: [
                        {
                          name: 'recap',
                          yesTextButton: { message: 'message.yes_confirm_button' },
                          noTextButton: { message: 'message.no_wrong_guess' },
                          children: [
                            {
                              name: 'correct',
                              messageFromUser: { message: 'message.yes_confirm_button' }
                            },
                            {
                              name: 'wrong',
                              messageFromUser: { message: 'message.no_wrong_guess' }
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ] : [
                {
                  name: 'recap',
                  yesTextButton: { message: 'message.yes_confirm_button' },
                  noTextButton: { message: 'message.no_wrong_guess' },
                  children: [
                    {
                      name: 'correct',
                      messageFromUser: { message: 'message.yes_confirm_button' }
                    },
                    {
                      name: 'wrong',
                      messageFromUser: { message: 'message.no_wrong_guess' }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }, {
      name: 'age',
      messageFromBot: {
        message: `message.age.guess[${personality}]`,
        nickname: nickname,
        age: rekognitionResponse.Age
      },
      children: [
        {
          name: 'correct',
          nodeRecupSentence: {
            message: `message.recap.guess.age[${recupPersonality}]`
          },
          messageFromBot: { message: 'message.age.response.bot.correct' },
          children: [
            {
              name: 'glasses',
              yesTextButton: { message: setGlassesButtonText(rekognitionResponse, 'yesButton') },
              noTextButton: { message: setGlassesButtonText(rekognitionResponse, 'noButton') },
              children: [
                {
                  name: 'correct',
                  nodeRecupSentence: {
                    message: {
                      with_glasses: `message.recap.guess.glasses.with_glasses[${recupPersonality}]`,
                      without_glasses: `message.recap.guess.glasses.without_glasses[${recupPersonality}]`
                    }
                  },
                  messageFromBot: { message: 'message.glasses.response.bot.correct' },
                  children: showBeardMustacheChildren(rekognitionResponse) ? [
                    {
                      name: 'beardMustache',
                      yesTextButton: { message: 'message.yes_thank_button' },
                      children: [
                        {
                          name: 'correct',
                          nodeRecupSentence: {
                            message: {
                              beard: `message.recap.guess.beard[${recupPersonality}]`,
                              mustache: `message.recap.guess.mustache[${recupPersonality}]`
                            }
                          },
                          children: [
                            {
                              name: 'recap',
                              yesTextButton: { message: 'message.yes_confirm_button' },
                              noTextButton: { message: 'message.no_wrong_guess' },
                              children: [
                                {
                                  name: 'correct',
                                  messageFromUser: { message: 'message.yes_confirm_button' }
                                },
                                {
                                  name: 'wrong',
                                  messageFromUser: { message: 'message.no_wrong_guess' }
                                }
                              ]
                            }
                          ]
                        },
                        {
                          name: 'wrong',
                          nodeRecupSentence: {
                            message: {
                              beard: '',
                              mustache: ''
                            }
                          },
                          children: [
                            {
                              name: 'recap',
                              yesTextButton: { message: 'message.yes_confirm_button' },
                              noTextButton: { message: 'message.no_wrong_guess' },
                              children: [
                                {
                                  name: 'correct',
                                  messageFromUser: { message: 'message.yes_confirm_button' }
                                },
                                {
                                  name: 'wrong',
                                  messageFromUser: { message: 'message.no_wrong_guess' }
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ] : [
                    {
                      name: 'recap',
                      yesTextButton: { message: 'message.yes_confirm_button' },
                      noTextButton: { message: 'message.no_wrong_guess' },
                      children: [
                        {
                          name: 'correct',
                          messageFromUser: { message: 'message.yes_confirm_button' }
                        },
                        {
                          name: 'wrong',
                          messageFromUser: { message: 'message.no_wrong_guess' }
                        }
                      ]
                    }
                  ]
                },
                {
                  name: 'wrong',
                  nodeRecupSentence: {
                    message: {
                      with_glasses: `message.recap.guess.glasses.with_glasses[${recupPersonality}]`,
                      without_glasses: `message.recap.guess.glasses.without_glasses[${recupPersonality}]`
                    }
                  },
                  messageFromUser: { message: 'message.glasses.response.user.wrong' },
                  children: showBeardMustacheChildren(rekognitionResponse) ? [
                    {
                      name: 'beardMustache',
                      yesTextButton: { message: 'message.yes_thank_button' },
                      children: [
                        {
                          name: 'correct',
                          nodeRecupSentence: {
                            message: {
                              beard: `message.recap.guess.beard[${recupPersonality}]`,
                              mustache: `message.recap.guess.mustache[${recupPersonality}]`
                            }
                          },
                          children: [
                            {
                              name: 'recap',
                              yesTextButton: { message: 'message.yes_confirm_button' },
                              noTextButton: { message: 'message.no_wrong_guess' },
                              children: [
                                {
                                  name: 'correct',
                                  messageFromUser: { message: 'message.yes_confirm_button' }
                                },
                                {
                                  name: 'wrong',
                                  messageFromUser: { message: 'message.no_wrong_guess' }
                                }
                              ]
                            }
                          ]
                        },
                        {
                          name: 'wrong',
                          nodeRecupSentence: {
                            message: {
                              beard: '',
                              mustache: ''
                            }
                          },
                          children: [
                            {
                              name: 'recap',
                              yesTextButton: { message: 'message.yes_confirm_button' },
                              noTextButton: { message: 'message.no_wrong_guess' },
                              children: [
                                {
                                  name: 'correct',
                                  messageFromUser: { message: 'message.yes_confirm_button' }
                                },
                                {
                                  name: 'wrong',
                                  messageFromUser: { message: 'message.no_wrong_guess' }
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ] : [
                    {
                      name: 'recap',
                      yesTextButton: { message: 'message.yes_confirm_button' },
                      noTextButton: { message: 'message.no_wrong_guess' },
                      children: [
                        {
                          name: 'correct',
                          messageFromUser: { message: 'message.yes_confirm_button' }
                        },
                        {
                          name: 'wrong',
                          messageFromUser: { message: 'message.no_wrong_guess' }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          name: 'wrong',
          nodeRecupSentence: {
            message: `message.recap.guess.age[${recupPersonality}]`
          },
          children: [
            {
              name: 'glasses',
              yesTextButton: { message: setGlassesButtonText(rekognitionResponse, 'yesButton') },
              noTextButton: { message: setGlassesButtonText(rekognitionResponse, 'noButton') },
              children: [
                {
                  name: 'correct',
                  nodeRecupSentence: {
                    message: {
                      with_glasses: `message.recap.guess.glasses.with_glasses[${recupPersonality}]`,
                      without_glasses: `message.recap.guess.glasses.without_glasses[${recupPersonality}]`
                    }
                  },
                  messageFromBot: { message: 'message.glasses.response.bot.correct' },
                  children: showBeardMustacheChildren(rekognitionResponse) ? [
                    {
                      name: 'beardMustache',
                      yesTextButton: { message: 'message.yes_thank_button' },
                      children: [
                        {
                          name: 'correct',
                          nodeRecupSentence: {
                            message: {
                              beard: `message.recap.guess.beard[${recupPersonality}]`,
                              mustache: `message.recap.guess.mustache[${recupPersonality}]`
                            }
                          },
                          children: [
                            {
                              name: 'recap',
                              yesTextButton: { message: 'message.yes_confirm_button' },
                              noTextButton: { message: 'message.no_wrong_guess' },
                              children: [
                                {
                                  name: 'correct',
                                  messageFromUser: { message: 'message.yes_confirm_button' }
                                },
                                {
                                  name: 'wrong',
                                  messageFromUser: { message: 'message.no_wrong_guess' }
                                }
                              ]
                            }
                          ]
                        },
                        {
                          name: 'wrong',
                          nodeRecupSentence: {
                            message: {
                              beard: '',
                              mustache: ''
                            }
                          },
                          children: [
                            {
                              name: 'recap',
                              yesTextButton: { message: 'message.yes_confirm_button' },
                              noTextButton: { message: 'message.no_wrong_guess' },
                              children: [
                                {
                                  name: 'correct',
                                  messageFromUser: { message: 'message.yes_confirm_button' }
                                },
                                {
                                  name: 'wrong',
                                  messageFromUser: { message: 'message.no_wrong_guess' }
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ] : [
                    {
                      name: 'recap',
                      yesTextButton: { message: 'message.yes_confirm_button' },
                      noTextButton: { message: 'message.no_wrong_guess' },
                      children: [
                        {
                          name: 'correct',
                          messageFromUser: { message: 'message.yes_confirm_button' }
                        },
                        {
                          name: 'wrong',
                          messageFromUser: { message: 'message.no_wrong_guess' }
                        }
                      ]
                    }
                  ]
                },
                {
                  name: 'wrong',
                  nodeRecupSentence: {
                    message: {
                      with_glasses: `message.recap.guess.glasses.with_glasses[${recupPersonality}]`,
                      without_glasses: `message.recap.guess.glasses.without_glasses[${recupPersonality}]`
                    }
                  },
                  messageFromUser: { message: 'message.glasses.response.user.wrong' },
                  children: showBeardMustacheChildren(rekognitionResponse) ? [
                    {
                      name: 'beardMustache',
                      yesTextButton: { message: 'message.yes_thank_button' },
                      children: [
                        {
                          name: 'correct',
                          nodeRecupSentence: {
                            message: {
                              beard: `message.recap.guess.beard[${recupPersonality}]`,
                              mustache: `message.recap.guess.mustache[${recupPersonality}]`
                            }
                          },
                          children: [
                            {
                              name: 'recap',
                              yesTextButton: { message: 'message.yes_confirm_button' },
                              noTextButton: { message: 'message.no_wrong_guess' },
                              children: [
                                {
                                  name: 'correct',
                                  messageFromUser: { message: 'message.yes_confirm_button' }
                                },
                                {
                                  name: 'wrong',
                                  messageFromUser: { message: 'message.no_wrong_guess' }
                                }
                              ]
                            }
                          ]
                        },
                        {
                          name: 'wrong',
                          nodeRecupSentence: {
                            message: {
                              beard: '',
                              mustache: ''
                            }
                          },
                          children: [
                            {
                              name: 'recap',
                              yesTextButton: { message: 'message.yes_confirm_button' },
                              noTextButton: { message: 'message.no_wrong_guess' },
                              children: [
                                {
                                  name: 'correct',
                                  messageFromUser: { message: 'message.yes_confirm_button' }
                                },
                                {
                                  name: 'wrong',
                                  messageFromUser: { message: 'message.no_wrong_guess' }
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ] : [
                    {
                      name: 'recap',
                      yesTextButton: { message: 'message.yes_confirm_button' },
                      noTextButton: { message: 'message.no_wrong_guess' },
                      children: [
                        {
                          name: 'correct',
                          messageFromUser: { message: 'message.yes_confirm_button' }
                        },
                        {
                          name: 'wrong',
                          messageFromUser: { message: 'message.no_wrong_guess' }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }]
  };
}
