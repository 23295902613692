import { AWS_REGION, COGNITO_USER_POOL, COGNITO_USER_CLIENT } from './constants';

export default {
  Auth: {
    region: AWS_REGION,
    userPoolId: COGNITO_USER_POOL,
    userPoolWebClientId: COGNITO_USER_CLIENT
  },
  authenticationFlowType: 'USER_PASSWORD_AUTH'
};
