
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { NUM_BG_IMG, ONE_TO_EXCLUDE_ZERO, PATH_S3_WELCOME_BG, URL_CDN, MAX_INACTIVITY_SECONDS } from './constants';
import { Authenticator } from '@aws-amplify/ui-vue';
import { signOut } from './service/cognitoService';
import '@aws-amplify/ui-vue/styles.css';

export default defineComponent({
  name: 'App',
  components: {
    Authenticator
  },
  data () {
    return {
      showLoader: false,
      URL_CDN: URL_CDN,
      MAX_INACTIVITY_SECONDS: MAX_INACTIVITY_SECONDS,
      PATH_S3_WELCOME_BG: PATH_S3_WELCOME_BG,
      randomIndex: Math.floor(Math.random() * NUM_BG_IMG + ONE_TO_EXCLUDE_ZERO),
      img: ''
    };
  },
  created () {
    this.img = `${this.URL_CDN}/${this.PATH_S3_WELCOME_BG}/componentBgWelcomeSlideshow${this.randomIndex}.png`;
    this.activityWatcher();
  },
  methods: {
    ...mapGetters(['fontFamily']),
    login () {
      this.$router.push({
        name: 'error'
      });
    },
    logout () {
      return signOut();
    },
    activityWatcher () {
      let secondsSinceLastActivity = 0;

      setInterval(() => {
        secondsSinceLastActivity++;
        if (secondsSinceLastActivity > this.MAX_INACTIVITY_SECONDS) {
          this.$router.push({
            name: 'welcomePage',
            path: '/'
          });
        }
      }, 1000);

      const activityDetected = () => {
        secondsSinceLastActivity = 0;
      };

      const activityEvents = [
        'mousedown', 'mousemove', 'keydown',
        'scroll', 'touchstart'
      ];

      activityEvents.forEach(eventName => {
        document.addEventListener(eventName, activityDetected, true);
      });
    }
  }
});
