
import { defineComponent } from 'vue';
import RestartSession from '@/components/RestartSession.vue';

export default defineComponent({
  name: 'topBar',
  components: { RestartSession },
  data () {
    return {
      restartSession: false
    };
  },
  methods: {
    goBack () {
      this.$router.push({
        name: 'welcomePage',
        path: '/'
      });
    },
    undo () {
      this.restartSession = false;
    }
  }
});
