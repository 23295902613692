import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageFromBot = _resolveComponent("MessageFromBot")!
  const _component_SetFeature = _resolveComponent("SetFeature")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MessageFromBot, {
      message: this.$t(`message.photo_agreement.bot.request[${_ctx.personality}]`)
    }, null, 8, ["message"]),
    _createVNode(_component_SetFeature, {
      noTextButton: this.$t('message.no_button'),
      yesTextButton: this.$t('message.yes_button'),
      onSetResponse: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setResponse($event, _ctx.userResponse)))
    }, null, 8, ["noTextButton", "yesTextButton"]),
    (_ctx.responseSetted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.userResponse)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_MessageFromBot, {
                  message: this.$t(`message.photo_agreement.bot.response.decline[${_ctx.personality > 1 ? _ctx.personality - 2 : _ctx.personality}]`)
                }, null, 8, ["message"]),
                _createVNode(_component_SetFeature, {
                  noTextButton: this.$t('message.i_confirm_button'),
                  yesTextButton: this.$t('message.continue_game'),
                  onSetResponse: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setSecondResponse($event, _ctx.userResponse)))
                }, null, 8, ["noTextButton", "yesTextButton"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}