import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "basis-3/4 px-10" }
const _hoisted_2 = { class: "inline-flex justify-center align-middle text-left px-3 text-white text-2xl" }
const _hoisted_3 = { class: "basis-1/4 px-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex flex-row justify-center align-middle py-1",
    style: _normalizeStyle(_ctx.setDisplay)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        type: "button",
        class: "inline-flex justify-center rounded-full border shadow-sm p-4 md:w-16 bg-white text-xl text-blue-600 font-bold",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.decreaseAge && _ctx.decreaseAge(...args)))
      }, " - "),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, _toDisplayString(this.ageByUser), 1)
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "inline-flex justify-center rounded-full border shadow-sm p-4 md:w-16 bg-white text-xl text-blue-600 font-bold",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.increaseAge && _ctx.increaseAge(...args)))
      }, " + ")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        type: "button",
        class: "w-full inline-flex justify-center rounded-full border shadow-sm p-4 bg-white text-xl uppercase text-red-600 hover:bg-gray-60 focus:outline-none focus:ring-2 focus:ring-offset-2 md:ml-5 md:w-48 md:text-md font-bold",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setResponse && _ctx.setResponse(...args)))
      }, _toDisplayString(this.ageButton), 1)
    ])
  ], 4))
}