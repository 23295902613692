
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import MessageFromBot from '@/components/MessageFromBot.vue';
import SetFeature from '@/components/SetFeature.vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'chooseBackground',
  components: {
    MessageFromBot,
    SetFeature
  },
  setup () {
    const t = useI18n();
    return t;
  },
  data () {
    return {
      yesButton: this.$t('message.yes_button'),
      noButton: this.$t('message.no_button'),
      userResponse: false,
      responseSetted: false,
      restartSession: false,
      photoTaken: false
    };
  },
  props: {
    personality: {
      type: Number
    }
  },
  methods: {
    ...mapGetters(['imgBase64']),
    setResponse (response: { userResponse: boolean }): void {
      this.userResponse = response.userResponse;
      this.responseSetted = true;
      if (this.userResponse) {
        this.$router.push({
          name: 'chooseBackground',
          path: '/chooseBackground'
        });
      }
    },
    setSecondResponse (response: { userResponse: boolean }): void {
      this.userResponse = response.userResponse;
      this.responseSetted = true;
      if (this.userResponse) {
        this.$router.push({
          name: 'chooseBackground',
          path: '/chooseBackground'
        });
      } else {
        this.$router.push({
          name: 'welcomePage',
          path: '/'
        });
      }
    }
  }
});
