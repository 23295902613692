
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import ShowPhoto from '@/components/ShowPhoto.vue';
export default defineComponent({
  name: 'endPage',
  components: {
    ShowPhoto
  },
  computed: {
    ...mapGetters(['imgNoBackground'])
  },
  methods: {
    restartGame () {
      this.$router.push({
        name: 'welcomePage',
        path: '/'
      });
    }
  }
});
