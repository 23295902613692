import { DEFAULT_EMOTION, FEMALE_GENDER } from '@/constants';
import { RekognitionResponse } from '@/types/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatRekognitionResponse (rekognitionResponse: RekognitionResponse): any {
  // in case of null, set default value
  return {
    Age: rekognitionResponse.Age,
    Smile: rekognitionResponse.Smile,
    Eyeglasses: rekognitionResponse.Eyeglasses
      ? rekognitionResponse.Eyeglasses
      : false,
    Sunglasses: rekognitionResponse.Sunglasses
      ? rekognitionResponse.Sunglasses
      : false,
    Gender: rekognitionResponse.Gender ? rekognitionResponse.Gender : FEMALE_GENDER,
    Beard: rekognitionResponse.Beard ? rekognitionResponse.Beard : false,
    Mustache: rekognitionResponse.Mustache ? rekognitionResponse.Mustache : false,
    Emotions: rekognitionResponse.Emotions
      ? rekognitionResponse.Emotions
      : DEFAULT_EMOTION,
    Soundness: rekognitionResponse.Soundness
  };
}
