
import { PATH_S3_BG, URL_CDN } from '@/constants';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'carouselBackground',
  setup () {
    const t = useI18n();
    return t;
  },
  data () {
    return {
      textButtonConfirm: this.$t('message.confirm_button'),
      textButtonSnapAgain: this.$t('message.snap_again'),
      PATH_S3_BG: PATH_S3_BG,
      URL_CDN: URL_CDN,
      images: [
        { id: 'rectangle.png', selected: true },
        { id: 'rectangle1.png', selected: false },
        { id: 'rectangle2.png', selected: false },
        { id: 'rectangle3.png', selected: false },
        { id: 'rectangle4.png', selected: false },
        { id: 'rectangle5.png', selected: false },
        { id: 'rectangle6.png', selected: false }
      ],
      imgSelected: 'rectangle.png'
    };
  },
  methods: {
    chooseBackground (index: number) {
      for (let i = 0; i < this.images.length; i++) {
        if (i !== index) {
          this.images[i].selected = false;
        } else {
          this.images[index].selected = true;
          this.imgSelected = this.images[index].id;
        }
      }
      this.$emit('changeBackground', this.imgSelected);
    },
    setResponse () {
      if (this.imgSelected) {
        this.$emit('getPhotoWithBackground', this.imgSelected);
      }
    },
    snapPhoto () {
      this.$emit('snapAgain');
    }
  }
});
